<template>
  <div class="view-header view-header--essai-details" v-if="pageTitle">
    <div class="view-header__header">
      <div id="header-action"></div>
      <Btn
        v-if="
        checkRoute() &&
        helperService.userHasPermission(`essai_edit,${$route.meta.permissions}`, 'AND')
        "
        class="action-btn"
        text="Modifier"
        icon="create"
        color="primary"
        :to="{
          name: `${$route.name}Edit`,
          params: {
            id: $route.params.id
          }
        }"
      />
    </div>
    <div class="view-header__footer">
      <Btn
        v-if="$route.params.id && $route.name.includes('Import') && !$route.name.includes('Export')"
        class="back-btn"
        round
        grow
        icon="arrow-left"
        :to="{
          name: 'essaiImportExport',
          params: {
            id: $route.params.id
          }
        }"
      />
      <Btn
        v-else-if="$route.params.id"
        class="back-btn"
        round
        grow
        icon="arrow-left"
        :to="{
          name: 'essais',
        }"
      />
      <h1 v-if="$route.name.includes('Import') && !$route.name.includes('Export')" class="page-title">
        Import - {{ $route.meta.title }}
        <span v-if="essai.protocole?.bpe">BPE</span>
      </h1>
      <h1 v-else class="page-title">
        {{ essai.nom }} - {{ $route.meta.title }}
        <span v-if="essai.protocole?.bpe">BPE</span>
      </h1>
    </div>
  </div>

  <div class="view-body">
    <router-view
      v-if="essai.id"
      @updateEssais="getEssai"
      :essai="essai"
    ></router-view>
  </div>

  <NavigationDropdownEssai :protocol="essai?.protocole" />
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import NavigationDropdownEssai from '@/views/componentsViews/navigationDropdown/NavigationDropdownEssai.vue'

export default {
  name: 'EssaiView',

  components: {
    NavigationDropdownEssai,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      essai: {},
    }
  },

  created() {
    this.getEssai()
  },

  methods: {
    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
        },
        (errorResponse) => {
          if (errorResponse.meta.status === 404) {
            this.$router.push({ name: 'notFound' })
          }
        },
      )
    },
    checkRoute() {
      let check

      switch (this.$route.name) {
        case 'essaiCalendar':
        case 'essaiFiles':
        case 'essaiFixedReports':
        case 'essaiFixedReport':
        case 'essaiImportExport':
        case 'essaiImport':
        case 'essaiImportDataAssignement':
        case 'essaiImportReport':
        case 'essaiImportResume':
          check = false
          break
        default:
          check = true
      }

      return check
    },
  },
}
</script>

<style scoped>
</style>
